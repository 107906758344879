.main-content {
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
  }
  
  .header-buttons {
    display: flex;
    gap: 15px;
  }
  
  .register-button {
    padding: 8px 15px;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .no-underline {
    text-decoration: none;
  }
  
  .register-button:hover {
    background-color: #0056b3;
  }
  
  
  .registration-table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  }
  
  .registration-table th, .registration-table td {
    padding: 12px 15px;
    text-align: left;
  }
  
  .registration-table th {
    background-color: #f4f4f4;
    font-weight: bold;
  }
  
  .registration-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .status {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 15px;
    color: #fff;
    font-weight: bold;
  }
  
  .status.registered {
    background-color: #2ecc71;
  }
  
  .status.pending {
    background-color: #f39c12;
  }
  
  .status.failed {
    background-color: #e74c3c;
  }
  
  .status.processing {
    background-color: #3498db;
  }
  
  /* General table styles */
.registration-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 18px;
  text-align: left;
}

.registration-table th, .registration-table td {
  padding: 12px 15px;
  border-bottom: 1px solid #ddd;
}

.registration-table th {
  background-color: #f4f4f4;
}

.registration-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.registration-table tr:hover {
  background-color: #f1f1f1;
}

/* Status label styles */
.status {
  padding: 5px 10px;
  border-radius: 20px;
  font-weight: bold;
  color: white;
}

.status.registered {
  background-color: #28a745; /* Green for registered */
}

.status.pending {
  background-color: #ffc107; /* Yellow for pending */
}

.status.failed {
  background-color: #dc3545; /* Red for failed */
}

.status.processing {
  background-color: #17a2b8; /* Blue for processing */
}

/* Pagination controls styles */
.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.pagination-controls button {
  padding: 10px 15px;
  margin: 0 5px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination-controls button:disabled {
  background-color: #d6d6d6;
  cursor: not-allowed;
}

.pagination-controls button:hover:not(:disabled) {
  background-color: #0056b3;
}

.pagination-controls span {
  font-size: 16px;
  font-weight: bold;
  margin: 0 10px;
}

/* Table Styles */
.registration-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  font-family: Arial, sans-serif;
}

.registration-table th, .registration-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #dddddd;
}

.registration-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.registration-table td {
  font-size: 14px;
  color: #333;
}

.status {
  padding: 5px 10px;
  border-radius: 4px;
}

.status.registered {
  background-color: #28a745;
  color: #fff;
}

.status.pending {
  background-color: #ffc107;
  color: #fff;
}

.status.failed {
  background-color: #dc3545;
  color: #fff;
}

.status.processing {
  background-color: #17a2b8;
  color: #fff;
}

/* View Details Button */
.view-details-button {
  padding: 10px 20px; /* Adjusted for a fat look */
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.view-details-button:hover {
  background-color: #0056b3;
}

/* Pagination Controls */
.pagination-controls {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;
}

.pagination-controls button {
  padding: 8px 16px;
  font-size: 14px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination-controls button:hover {
  background-color: #0056b3;
}

.pagination-controls button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.pagination-controls span {
  font-size: 14px;
}

/* Table Styles */
.registration-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  font-family: Arial, sans-serif;
}

.registration-table th, .registration-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #dddddd;
}

.registration-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.registration-table td {
  font-size: 14px;
  color: #333;
}

.status {
  padding: 5px 10px;
  border-radius: 4px;
}

.status.registered {
  background-color: #28a745;
  color: #fff;
}

.status.pending {
  background-color: #ffc107;
  color: #fff;
}

.status.failed, .status.cancelled {
  background-color: #dc3545;
  color: #fff;
}

.status.processing {
  background-color: #17a2b8;
  color: #fff;
}

/* View Details Button */
.view-details-button, .cancel-button {
  padding: 10px 20px; /* Adjusted for a fat look */
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* View Details Button Style */
.view-details-button {
  background-color: #007bff;
  color: #fff;
}

.view-details-button:hover {
  background-color: #0056b3;
}

/* Cancel Button Style */
.cancel-button {
  background-color: #dc3545;
  color: white;
}

.cancel-button:hover {
  background-color: #c82333;
}

.cancel-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Pagination Controls */
.pagination-controls {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;
}

.pagination-controls button {
  padding: 8px 16px;
  font-size: 14px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination-controls button:hover {
  background-color: #0056b3;
}

.pagination-controls button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.pagination-controls span {
  font-size: 14px;
}

/* Table Styles */
.registration-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  font-family: Arial, sans-serif;
}

.registration-table th, .registration-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #dddddd;
}

.registration-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.registration-table td {
  font-size: 14px;
  color: #333;
}

.status {
  padding: 5px 10px;
  border-radius: 4px;
}

.status.registered {
  background-color: #28a745;
  color: #fff;
}

.status.pending {
  background-color: #ffc107;
  color: #fff;
}

.status.failed, .status.cancelled {
  background-color: #dc3545;
  color: #fff;
}

.status.processing {
  background-color: #17a2b8;
  color: #fff;
}

/* View Details Button */
.view-details-button, .cancel-button, .resend-button {
  padding: 10px 20px; /* Adjusted for a fat look */
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* View Details Button Style */
.view-details-button {
  background-color: #007bff;
  color: #fff;
}

.view-details-button:hover {
  background-color: #0056b3;
}

/* Cancel Button Style */
.cancel-button {
  background-color: #dc3545;
  color: white;
}

.cancel-button:hover {
  background-color: #c82333;
}

.cancel-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Resend Button Style */
.resend-button {
  background-color: #ffc107;
  color: white;
}

.resend-button:hover {
  background-color: #e0a800;
}

/* Pagination Controls */
.pagination-controls {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;
}

.pagination-controls button {
  padding: 8px 16px;
  font-size: 14px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination-controls button:hover {
  background-color: #0056b3;
}

.pagination-controls button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.pagination-controls span {
  font-size: 14px;
}

/* Modal Styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.modal h3 {
  margin-bottom: 10px;
}

.modal p {
  font-size: 16px;
  margin-bottom: 20px;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
}

.confirm-button {
  background-color: #dc3545;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.confirm-button:hover {
  background-color: #c82333;
}

.cancel-modal-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cancel-modal-button:hover {
  background-color: #0056b3;
}

.filter-options {
  display: flex;
  gap: 15px; /* Minimal spacing between each filter */
  flex-wrap: wrap; /* Automatically adjusts to smaller screens */
  justify-content: start;
  padding: 10px 15px;
  background-color: #f5f5f5; /* Subtle background for clarity */
  border-radius: 8px;
  border: 1px solid #e0e0e0;
}

.filter-checkbox {
  display: flex;
  align-items: center;
  gap: 8px; /* Space between checkbox and label */
  font-size: 14px;
  cursor: pointer;
  font-weight: 500;
  color: #333;
  transition: color 0.2s;
}

.filter-checkbox input {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.filter-checkbox:hover {
  color: #007bff; /* Subtle highlight on hover for interactivity */
}

@media (max-width: 768px) {
  .filter-options {
    gap: 10px; /* Reduce spacing for smaller screens */
  }

  .filter-checkbox {
    font-size: 13px; /* Slightly smaller font size for compactness */
  }
}



/* Rest of the CSS for table, buttons, etc. remains unchanged */
