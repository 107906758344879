/* src/styles/NotfoundPage.css */

/* Ensure the parent container uses full viewport height */
.pageNotFound {
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    height: 100vh;
    /* Full height of the viewport */
    text-align: center;
    /* Center text inside */
    flex-direction: column;
    /* Stack elements vertically */
}

.notFoundImg {
    max-width: 400px;
    width: 100%;
}

.home-link {
    text-decoration: none;
    color: #007bff;
    /* Bootstrap primary color */
    font-size: 18px;
    margin-top: 20px;
}

.home-link:hover {
    color: #0056b3;
    /* Darker blue on hover */
}