  /* src/styles/landing/FeatureStepsSection.css */
.feature-steps-section {
    display: flex;
    flex-direction: column;
    padding: 40px;
    background-color: #f9f9fb;
    position: relative;
  }
  
  .feature-step {
    display: flex;
    align-items: flex-start;
    margin-bottom: 40px;
    position: relative;
  }
  
  .feature-step.left {
    flex-direction: row;
    margin-left: 0;
  }
  
  .feature-step.right {
    flex-direction: row;
    margin-left: auto;
  }
  
  .feature-content {
    max-width: 600px;
    text-align: left;
  }
  
  /* Styling the progress line */
  /* .progress-line {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 4px;
    height: 100%;
    background-color: #e0e0e0;
  }
  
  .progress-line::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(var(--progress, 0) * 100%);
    background-color: #6a1b9a;
    transition: height 1s ease-out;
  } */

  .progress-line {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 4px;
    height: calc(100% - 40px); /* Reduce the height slightly */
    background-color: #e0e0e0;
    margin-bottom: 40px; /* Add bottom margin for extra space */
  }
  
  .progress-line::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(var(--progress, 0) * 100%);
    background-color: #c4ccd8;
    transition: height 1s ease-out;
  }
  
  
  /* Styling the step numbers along the progress line */
  .step-number {
    position: absolute;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #1d2b64;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    transition: background-color 0.3s ease;
  }
  
  .feature-step.left .step-number {
    right: calc(50% + 30px); /* Place to the left of the vertical line */
  }
  
  .feature-step.right .step-number {
    left: calc(50% + 30px); /* Place to the right of the vertical line */
  }
  
  .step-number:nth-child(1) {
    top: 1%;
    /* left: -35%; */

  }
  
  .step-number:nth-child(2) {
    top: 26%;
    /* left: -39%; */
  }
  
  .step-number:nth-child(3) {
    top: 48%;
    /* left: -39%; */
  }
  
  .step-number:nth-child(4) {
    top: 70%;
    /* left: -39%; */
  }
  
  .feature-steps-section {
    display: flex;
    flex-direction: column;
    padding: 40px;
    padding-bottom: 80px; /* Add padding to create space at the bottom */
    background-color: #f9f9fb;
    position: relative;
  }
  