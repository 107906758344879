.dashboard {
  padding: 30px;
  background-color: #f4f7f9; /* Light background for contrast */
  font-family: 'Arial', sans-serif; /* Font style */
}

.dash-header {
  text-align: center; /* Center align the header */
  margin-bottom: 20px;
}

.dash-header h1 {
  margin-bottom: 5px;
  font-size: 2.5em; /* Larger font size for title */
  color: #1d2b64; /* Dark blue color for header */
}

.dash-header p {
  font-size: 1.2em; /* Slightly larger font size for subtitle */
  color: #6c757d; /* Gray color for subtitle */
}

.overview {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  gap: 20px; /* Space between cards */
}

.card {
  width: 30%;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  transition: transform 0.2s, box-shadow 0.2s; /* Animation on hover */
}

.card:hover {
  transform: translateY(-5px); /* Lift effect on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Deeper shadow on hover */
}

.total-collection {
  margin-top: 30px;
  background-color: #fff; /* White background for contrast */
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow */
}

.total-collection p {
  color: #6c757d; /* Gray color for normal text */
}

.total-collection h2 {
  font-size: 2em; /* Larger font for total amount */
  color: #28a745; /* Green color for total amount */
  margin: 10px 0; /* Margin for spacing */
}

.chart-bar {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.total-collection {
  margin-top: 30px;
  background-color: #fff; /* White background */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow */
}

.total-collection h3 {
  text-align: center;
  color: #eff0f5; /* Dark blue color for the title */
  margin-bottom: 20px; /* Space below the title */
}

.total-collection-data {
  display: grid; /* Use CSS Grid for layout */
  grid-template-columns: repeat(3, 1fr); /* Three equal columns */
  gap: 20px; /* Space between grid items */
}

.total-collection-data div {
  background-color: #f9f9f9; /* Light background for items */
  padding: 15px;
  border-radius: 8px; /* Rounded corners */
  transition: transform 0.2s, box-shadow 0.2s; /* Animation on hover */
}

.total-collection-data div:hover {
  transform: translateY(-3px); /* Lift effect on hover */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Deeper shadow on hover */
}

.total-collection-data span {
  display: block; /* Block display for labels */
  font-weight: bold; /* Bold text for labels */
  color: #6c757d; /* Gray color for labels */
  margin-bottom: 5px; /* Space below labels */
}

.total-collection-data h2 {
  color: #28a745; /* Green color for total amount */
  margin: 0; /* Remove margin for consistency */
}

.total-collection-data p {
  margin: 0; /* Remove margin for consistency */
  color: #333; /* Dark color for text */
}
