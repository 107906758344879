.analytics-container {
    padding: 24px;
    background: #f0f2f5;
    min-height: 100vh;
}

.analytics-container h1 {
    margin-bottom: 24px;
    color: #1a3353;
}

.analytics-tabs {
    background: #fff;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.analytics-section {
    padding: 24px 0;
}

.section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

.time-frame-selector {
    margin-bottom: 24px;
}

.stats-row {
    margin-bottom: 24px;
}

.stats-row .ant-card {
    height: 100%;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.graph-card {
    margin-top: 24px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.graph-card h2 {
    margin-bottom: 16px;
    color: #1a3353;
}

.ant-statistic-title {
    color: #666;
}

.ant-statistic-content {
    color: #1a3353;
}

.ant-tabs-nav {
    margin-bottom: 24px;
}

.ant-tabs-tab {
    padding: 12px 24px !important;
}

.ant-tabs-tab-active {
    background: #e6f7ff;
    border-radius: 8px 8px 0 0;
}
