/* General Styling for the Register Mandate Form */
.register-mandate {
  max-width: 900px;
  margin: 2rem auto;
  padding: 35px;
  border: 1px solid rgba(29, 43, 100, 0.2);
  border-radius: 24px;
  background: linear-gradient(165deg, #ffffff 0%, #f8f9ff 100%);
  box-shadow: 0 10px 40px rgba(29, 43, 100, 0.08);
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  overflow-y: auto;
  max-height: 88vh;
  position: relative;
}

.register-mandate:hover {
  transform: translateY(-2px);
  box-shadow: 0 15px 50px rgba(29, 43, 100, 0.12);
}

/* Form Elements Styling */
.mandate-form .form-group {
  margin-bottom: 25px;
  position: relative;
}

.mandate-form label {
  display: block;
  margin-bottom: 10px;
  font-weight: 600;
  color: #1d2b64;
  font-size: 1.1rem;
  letter-spacing: 0.3px;
}

.mandate-form input, 
.mandate-form select {
  width: 100%;
  padding: 16px;
  border: 2px solid rgba(29, 43, 100, 0.15);
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.9);
  font-size: 1.05rem;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 2px 8px rgba(29, 43, 100, 0.05);
}

.mandate-form input:hover,
.mandate-form select:hover {
  border-color: rgba(29, 43, 100, 0.3);
  background-color: #ffffff;
}

.mandate-form input:focus,
.mandate-form select:focus {
  background-color: #ffffff;
  border-color: #1d2b64;
  box-shadow: 0 4px 12px rgba(29, 43, 100, 0.15);
  outline: none;
}

/* Enhanced Button Styling */
.submit-button,
.cancel-button {
  padding: 16px 32px;
  font-size: 1.1rem;
  font-weight: 600;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  min-width: 160px;
}

.submit-button {
  background: linear-gradient(135deg, #1d2b64 0%, #283779 100%);
  color: #ffffff;
  box-shadow: 0 4px 15px rgba(29, 43, 100, 0.2);
}

.submit-button:hover {
  background: linear-gradient(135deg, #283779 0%, #1d2b64 100%);
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(29, 43, 100, 0.25);
}

.cancel-button {
  background: linear-gradient(135deg, #f0f2f8 0%, #e6e9f2 100%);
  color: #1d2b64;
  margin-left: 20px;
  border: 1px solid rgba(29, 43, 100, 0.1);
}

.cancel-button:hover {
  background: linear-gradient(135deg, #e6e9f2 0%, #d8dce8 100%);
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(29, 43, 100, 0.1);
}

/* Improved Modal Styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(29, 43, 100, 0.4);
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: linear-gradient(165deg, #ffffff 0%, #f8f9ff 100%);
  padding: 35px;
  border-radius: 24px;
  width: 450px;
  text-align: center;
  border: 1px solid rgba(29, 43, 100, 0.1);
  box-shadow: 0 20px 60px rgba(29, 43, 100, 0.15);
  transform: scale(1);
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

/* Enhanced Template Card Layout */
.template-cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 25px;
  padding: 25px 0;
}

.template-card {
  background: linear-gradient(165deg, #ffffff 0%, #f8f9ff 100%);
  border: 1px solid rgba(29, 43, 100, 0.1);
  border-radius: 16px;
  padding: 25px;
  box-shadow: 0 8px 25px rgba(29, 43, 100, 0.08);
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  position: relative;
}

.template-card:hover {
  transform: translateY(-4px) scale(1.02);
  box-shadow: 0 12px 30px rgba(29, 43, 100, 0.12);
}

.template-card h3 {
  font-size: 1.3rem;
  color: #1d2b64;
  margin-bottom: 12px;
  font-weight: 600;
}

.template-card p {
  font-size: 1.05rem;
  color: #4a5578;
  margin-bottom: 20px;
  line-height: 1.5;
}

/* Template Card Actions */
.template-actions {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  margin-top: 20px;
}

.template-actions button {
  flex: 1;
  padding: 12px;
  border: none;
  border-radius: 10px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.template-actions .edit-button {
  background: linear-gradient(135deg, #4caf50 0%, #45a049 100%);
  color: white;
}

.template-actions .edit-button:hover {
  background: linear-gradient(135deg, #45a049 0%, #3d8b40 100%);
  transform: translateY(-2px);
}

.template-actions .delete-button {
  background: linear-gradient(135deg, #e53935 0%, #d32f2f 100%);
  color: white;
}

.template-actions .delete-button:hover {
  background: linear-gradient(135deg, #d32f2f 0%, #c62828 100%);
  transform: translateY(-2px);
}

/* Template List Improvements */
.template-list {
  background: linear-gradient(165deg, #ffffff 0%, #f8f9ff 100%);
  border-radius: 16px;
  padding: 20px;
  box-shadow: 0 8px 25px rgba(29, 43, 100, 0.08);
}

.template-list li {
  padding: 16px;
  border-bottom: 1px solid rgba(29, 43, 100, 0.1);
  transition: all 0.3s ease;
  border-radius: 12px;
  margin-bottom: 8px;
}

.template-list li:hover {
  background: linear-gradient(135deg, #1d2b64 0%, #283779 100%);
  color: #ffffff;
  transform: translateX(4px);
}

/* Responsive Design Improvements */
@media (max-width: 768px) {
  .register-mandate {
    padding: 25px;
    margin: 1rem;
  }

  .template-cards-container {
    grid-template-columns: 1fr;
  }

  .submit-button,
  .cancel-button {
    width: 100%;
    margin: 10px 0;
  }

  .modal-content {
    width: 90%;
    margin: 20px;
  }
}
