/* src/styles/landing/PopupForm.css */
.popup-form {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-form-content {
  background: #c4ccd8;
  padding: 30px;
  border-radius: 8px;
  width: 400px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  position: relative;
  font-family: 'Arial', sans-serif;
  box-sizing: border-box;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  color: #333;
}

form label {
  display: block;
  margin-bottom: 8px;
  color: #333;
  font-weight: normal; /* Make labels not bold */
}

form input, form textarea {
  width: 100%;
  padding: 12px;
  margin-top: 5px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
  transition: border-color 0.3s, box-shadow 0.3s;
}

form input:focus, form textarea:focus {
  border-color: #1d2b64;
  box-shadow: 0 0 5px rgba(29, 43, 100, 0.2);
  outline: none;
}

form button {
  padding: 12px 20px;
  background: #1d2b64;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

form button:hover {
  background: #16224f;
}

.toast-message {
  position: fixed;
  top: 100px; /* Adjusted to move the toast message slightly down */
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffffff; /* White background */
  color: #000000; /* Black text color */
  padding: 10px 20px;
  border: 1px solid #c3e6cb;
  border-radius: 10px; /* Slightly curved edges */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 2000; /* Ensure the toast is above other elements */
  font-size: 1rem;
  text-align: center; /* Center the text */
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Center items vertically */
  gap: 10px; /* Space between icon and text */
  opacity: 0; /* Start hidden */
  transition: opacity 0.5s ease-in-out; /* Smooth transition for opacity */
}

.toast-message.show {
  opacity: 1; /* Show toast */
}