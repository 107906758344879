.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  position: relative;
  width: 90%;
  max-width: 500px;
}

.modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
}

.transaction-details {
  margin-top: 1.5rem;
}

.detail-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 0.5rem 0;
  border-bottom: 1px solid #eee;
}

.detail-label {
  font-weight: bold;
  color: #666;
}

.detail-value {
  color: #333;
}

.bounce-memo-section {
  margin-top: 1.5rem;
  text-align: center;
}

.bounce-memo-button {
  background-color: #ff4444;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.bounce-memo-button:hover {
  background-color: #cc0000;
}

.view-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #0066cc;
}

.view-button:hover {
  color: #003366;
}
