.registration-container {
    padding: 20px;
    text-align: center;
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 10px;
}

.header-container h2 {
    margin: 0;
}

.close-icon {
    cursor: pointer;
    color: #333;
    transition: color 0.3s ease;
}

.close-icon:hover {
    color: #dc3545;
}

.drag-drop-zone {
    border: 2px dashed #ccc;
    padding: 40px;
    margin: 50px 0;
    cursor: pointer;
    border-radius: 8px;
    transition: border-color 0.3s;
}

.drag-drop-zone:hover {
    border-color: #007bff;
}

.button-group {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}

button {
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upload-button,
.register-button,
.save-button,
.back-button,
.preview-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    height: 40px;
    line-height: 20px;
}

button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.upload-button:hover:not(:disabled),
.register-button:hover:not(:disabled),
.save-button:hover:not(:disabled),
.back-button:hover:not(:disabled),
.preview-button:hover:not(:disabled) {
    background-color: #0056b3;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 600px;
    text-align: left;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    max-height: 80vh;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #333;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    overflow-x: auto;
}

table th, table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
    font-size: 0.9rem;
}

table th {
    background-color: #f4f4f4;
    font-weight: bold;
}

table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

.preview-table {
    overflow-y: auto;
    max-height: 300px;
}

.footer {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

.back-button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    font-size: 1rem;
    transition: background-color 0.3s, box-shadow 0.3s;
    display: inline-block;
}

.back-button:hover {
    background-color: #0056b3;
    box-shadow: 0px 4px 8px rgba(0, 91, 187, 0.3);
}

.back-button:active {
    background-color: #004494;
    box-shadow: none;
}

.file-info {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.file-name {
    font-size: 1rem;
    color: #333;
}

.clear-button {
    background: transparent;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
    color: #007bff;
    padding: 0;
}

.clear-button:hover {
    color: #0056b3;
}
