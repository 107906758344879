.customer-engagement-container {
  padding: 20px;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.section-wrapper {
  margin-bottom: 30px;
}

.horizontal-scroll {
  overflow-x: auto;
  padding: 10px 0;
}

.metrics-row, .tools-row {
  display: flex;
  gap: 20px;
  min-width: min-content;
}

.channels-row {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.analytics-row {
  display: flex;
  gap: 20px;
}

.metric-card, .tool-card {
  flex: 0 0 300px;
  transition: transform 0.2s;
}

.channel-card {
  width: 100%;
  transition: all 0.3s ease;
  border: none !important;
  background: linear-gradient(145deg, #ffffff 0%, #f8f9fa 100%);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.channel-card .card-body {
  display: flex;
  align-items: center;
  padding: 24px;
  gap: 24px;
}

.channel-content {
  flex-grow: 1;
}

.channel-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 8px;
}

.channel-description {
  color: #6c757d;
  margin-bottom: 0;
  font-size: 0.95rem;
}

.metric-card:hover, .tool-card:hover {
  transform: translateY(-5px);
}

.channel-card:hover {
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.08);
  transform: translateY(-2px);
}

.analytics-card {
  flex: 3;
}

.summary-card {
  flex: 1;
}

.analytics-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.section-title {
  margin-bottom: 20px;
  color: #2c3e50;
  font-weight: 600;
}

.metric-icon, .tool-icon {
  font-size: 2rem;
  margin-bottom: 10px;
}

.channel-icon {
  font-size: 2.5rem;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f8f9fa;
  border-radius: 12px;
  flex-shrink: 0;
}

.summary-list {
  list-style: none;
  padding: 0;
}

.summary-list li {
  margin-bottom: 10px;
}

.configure-button, .view-report-button {
  padding: 10px 20px;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  border: none;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.configure-button {
  background-color: #2563eb !important;
  color: white !important;
  border: none !important;
  padding: 8px 20px !important;
  border-radius: 6px !important;
  min-width: 120px;
}

.configure-button:hover {
  background-color: #1d4ed8 !important;
  box-shadow: 0 4px 6px rgba(37, 99, 235, 0.2);
}

.view-report-button {
  background-color: #1976d2;
  color: white;
}

.view-report-button:hover {
  background-color: #1565c0;
  box-shadow: 0 2px 4px rgba(25, 118, 210, 0.2);
}

.configure-button:active, .view-report-button:active {
  transform: translateY(1px);
}

.configure-button:focus, .view-report-button:focus {
  outline: none;
  ring: 2px solid rgba(25, 118, 210, 0.2);
}

@media (max-width: 768px) {
  .analytics-row {
    flex-direction: column;
  }
  
  .analytics-card, .summary-card {
    width: 100%;
  }

  .channel-card .card-body {
    flex-direction: column;
    text-align: center;
  }

  .channel-content {
    text-align: center;
  }
}
