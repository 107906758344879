/* Container */
.collections-container {
  padding: 25px;
  background-color: #f7f9fc;
  border-radius: 12px;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.15);
  max-width: 1200px;
  margin: 0 auto;
  animation: fadeIn 0.5s ease-in-out;
}

/* Search Bars */
.search-bars {
  display: flex;
  gap: 20px;
  margin-bottom: 25px;
  align-items: center;
}

.search-input-container {
  position: relative;
  flex-grow: 1;
}

.search-input {
  width: 80%;
  padding: 12px 40px;
  border: 1px solid #c4ccd8;
  border-radius: 8px;
  font-size: 16px;
  transition: all 0.3s ease;
}

.search-input:focus {
  border-color: #1d2b64;
  box-shadow: 0 0 10px rgba(29, 43, 100, 0.3);
  outline: none;
}

/* Loan Info Container with Pie Chart */
.loan-info-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;
  gap: 30px;
}

.loan-info {
  flex: 1;
  padding: 25px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.pie-chart-container {
  flex: 1;
  background: white;
  padding: 25px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.pie-chart-container:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.pie-chart-container h3 {
  text-align: center;
  margin-bottom: 20px;
  color: #1d2b64;
  font-size: 1.2rem;
  font-weight: 600;
}

.custom-tooltip {
  background-color: rgba(255, 255, 255, 0.95);
  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border: 1px solid #eee;
}

.custom-tooltip p {
  margin: 0;
  color: #333;
  font-weight: 500;
}

/* Rest of the existing CSS remains the same */
.register-button.no-underline {
  background: linear-gradient(135deg, #1d2b64, #283779);
  color: white;
  padding: 12px 24px;
  border-radius: 8px;
  font-weight: 600;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s ease;
  border: none;
  cursor: pointer;
  white-space: nowrap;
  box-shadow: 0 4px 6px rgba(29, 43, 100, 0.2);
}

.register-button.no-underline:hover {
  background: linear-gradient(135deg, #283779, #1d2b64);
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(29, 43, 100, 0.3);
}

.register-button.no-underline:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(29, 43, 100, 0.2);
}

.search-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
}

/* Table Styles */
.collections-table, .repayment-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.collections-table thead, .repayment-table thead {
  background-color: #dfe1eb;
  color: #0f0202;
}

.collections-table th, .repayment-table th {
  padding: 15px;
  text-align: left;
  font-weight: 600;
}

.collections-table td, .repayment-table td {
  padding: 15px;
  background-color: #ffffff;
  border-bottom: 1px solid #c4ccd8;
  border-radius: 8px;
}

.collections-table tbody tr:hover, .repayment-table tbody tr:hover {
  background-color: #f1f3f5;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

/* Progress Bar */
.progress-bar-container {
  background-color: #c4ccd8;
  border-radius: 5px;
  height: 12px;
  width: 200px;
  overflow: hidden;
  position: relative;
}

.progress-bar {
  background-color: #1d2b64;
  height: 100%;
  border-radius: 5px;
  transition: width 0.4s ease-in-out;
}

/* Link Styling */
.view-link {
  color: #080202;
  background-color: #cdd0dd;
  padding: 10px 15px;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  font-weight: 400;
  transition: background-color 0.3s ease, transform 0.3s ease;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border-color: #cdd0dd;
}

.view-link:hover {
  background-color: #89a1ef;
  transform: translateY(-3px);
}

.view-link::before {
  content: '';
  position: absolute;
  top: 0;
  left: -50%;
  width: 200%;
  height: 300%;
  background-color: rgba(255, 255, 255, 0.2);
  transform: rotate(45deg);
  transition: left 0.5s ease;
}

.view-link:hover::before {
  left: 100%;
}

.request-link {
  color: #080202;
  background-color: #cdd0dd;
  padding: 10px 15px;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  font-weight: 400;
  transition: background-color 0.3s ease, transform 0.3s ease;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border-color: #cdd0dd;
}

.request-link:hover {
  background-color: #89a1ef;
  transform: translateY(-3px);
}

.request-link:hover::before {
  left: 100%;
}

.request-link:before {
  content: '';
  position: absolute;
  top: 0;
  left: -50%;
  width: 200%;
  height: 300%;
  background-color: rgba(255, 255, 255, 0.2);
  transform: rotate(45deg);
  transition: left 0.5s ease;
}

.request-link .request-icon {
  margin-right: 5px; /* Space between an icon (if needed) and text */
}

/* Loan Details Container */
.loan-details-container {
  background-color: #ffffff;
  padding: 25px;
  border-radius: 12px;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.15);
  position: relative;
  animation: slideIn 0.5s ease-in-out;
}

.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: transparent;
  border: none;
  font-size: 20px;
  color: #777;
  cursor: pointer;
  transition: color 0.3s ease;
}

.close-button:hover {
  color: #dc3545;
}

.loan-details h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #1d2b64;
}

.loan-info p {
  margin-bottom: 15px;
  font-size: 16px;
  color: #555;
  line-height: 1.5;
}

.loan-info strong {
  color: #1d2b64;
  font-weight: 600;
  margin-right: 8px;
}

/* Status Badge */
.status-badge {
  padding: 5px 12px;
  border-radius: 15px;
  font-size: 14px;
  font-weight: 500;
}

.status-badge.success {
  background-color: #28a745;
  color: white;
}

.status-badge.failure {
  background-color: #dc3545;
  color: white;
}

.status-badge.bounce {
  background-color: #ffc107;
  color: white;
}

/* Pagination */
.pagination-controls {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination-controls button {
  background-color: #1d2b64;
}

.pagination-button {
  background-color: #f1f1f1;
  color: #333;
  border: none;
  padding: 10px 15px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.pagination-button:hover {
  background-color: #1d2b64;
  color: white;
}

.pagination-button.active {
  background-color: #1d2b64;
  color: white;
}

/* Unselected pagination text - not blue */
.pagination-controls span {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.pagination-controls span.active {
  color: #1d2b64;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

/* Total Collection Container */
.total-collection {
  background: linear-gradient(135deg, #1d2b64, #f8cdda);
  padding: 25px;
  border-radius: 15px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  color: white;
  margin-bottom: 30px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.total-collection h3 {
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: 700;
  letter-spacing: 1px;
  color: #ffffff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

/* Total Collection Data Styling */
.total-collection-data {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.scheduled-debit, .scheduled-time {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 18px;
}

.scheduled-debit span, .scheduled-time span {
  font-weight: 400;
  color: rgba(255, 255, 255, 0.9);
}

.scheduled-debit p, .scheduled-time p {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  color: #ffffff;
  transition: color 0.3s ease;
}

/* Hover effect but without pulse */
.total-collection:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 28px rgba(0, 0, 0, 0.25);
}

/* Icon Styling */
.scheduled-debit::before, .scheduled-time::before {
  content: '';
  display: inline-block;
  width: 24px;
  height: 24px;
  background-size: cover;
}

.scheduled-debit::before {
  background-image: url('https://cdn-icons-png.flaticon.com/512/711/711285.png');
}

.scheduled-time::before {
  background-image: url('https://cdn-icons-png.flaticon.com/512/3069/3069182.png');
}

.scheduled-debit span, .scheduled-time span{
  color: black;
}

/* CSS for the Debit Button in the Footer */
.footer {
  bottom: 0;
  left: 0;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  background-color: #f8f9fa;
}

.debit-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: auto;
}

.debit-button:hover {
  background-color: #0056b3;
}

/* Centered Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-collection-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.modal-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.modal-buttons button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-buttons button:first-child {
  background-color: #28a745;
  color: #fff;
}

.modal-buttons button:last-child {
  background-color: #dc3545;
  color: #fff;
}

/* Highlight selected row */
.selected-row {
  background-color: #e0f7fa;
}

.selected-row:hover {
  background-color: #b2ebf2;
}

.transaction-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.transaction-header h3 {
  margin: 0;
  margin-right: 20px;
}
