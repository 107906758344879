.landing-header {
  background-color: #f8f9fa; /* Optional: Change as per your theme */
  padding: 15px 30px;
  border-bottom: 2px solid #e2e3e5;
}

.header-container {
  display: flex;
  justify-content: space-between; /* Space between logo and navigation links */
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
}

.logo-image {
  width: 40px;  /* Adjust width as necessary */
  height: 40px; /* Make height equal to width */
  margin-right: 10px;
}

.site-name {
  font-style: italic; /* Italicize the website name */
  font-weight: bold;  /* Optional: Add boldness */
  font-size: 24px;    /* Adjust the font size */
  margin: 0;
}

.navigation {
  display: flex;
}

.nav-link {
  margin: 0 15px;  /* Space between each link */
  text-decoration: none;
  color: #343a40;
  font-weight: 500;
}

.nav-link:hover {
  color: #007bff; /* Color change on hover */
}

.login-button-landing-page {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
}

.login-button-landing-page:hover {
  background-color: #0056b3; /* Darker shade on hover */
}
