/* src/styles/landing/CollectionFeaturesSection.css */
.collection-features-section {
    padding: 40px;
    background-color: #f4f7fb;
    text-align: center;
  }
  
  .collection-title {
    font-size: 28px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .collection-features {
    max-height: 300px; /* Set the max-height to make it scrollable */
    overflow-y: auto; /* Enables vertical scrolling */
    padding-right: 10px;
    scrollbar-width: thin;
  }
  
  .feature-item {
    margin-bottom: 20px;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  }
  
  .feature-item h3 {
    font-size: 20px;
    color: #444;
  }
  
  .feature-item p {
    font-size: 16px;
    color: #666;
  }
  
  /* src/styles/landing/FeatureStepsSection.css */
.feature-steps-section {
    display: flex;
    flex-direction: column;
    padding: 40px;
    background-color: #f9f9fb;
  }
  
  .feature-step {
    display: flex;
    align-items: flex-start;
    margin-bottom: 40px;
  }
  
  .step-number {
    font-size: 24px;
    color: #6a1b9a;
    background-color: #e0e0e0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
  }
  
  .feature-content {
    max-width: 600px;
  }
  
  .feature-content h3 {
    font-size: 22px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .feature-content ul {
    list-style-type: none;
    padding: 0;
  }
  
  .feature-content ul li {
    font-size: 16px;
    color: #555;
    position: relative;
    padding-left: 20px;
    margin-bottom: 10px;
  }
  
  .feature-content ul li:before {
    content: '✔';
    color: #00c853;
    position: absolute;
    left: 0;
    font-size: 14px;
    line-height: 1;
  }
  