.sidebar {
    width: 250px;
    background-color: #f4f4f4;
    height: 100vh;
    padding: 20px;
  }
  
  .sidebar-logo h2 {
    font-size: 20px;
  }
  
  .sidebar-menu {
    list-style: none;
    padding: 0;
  }
  
  .sidebar-menu li {
    margin: 20px 0;
  }
  
  .sidebar-menu li a {
    text-decoration: none;
    color: #000;
  }
  
  .sidebar a {
    padding: 10px;
    display: block;
    color: black;
    text-decoration: none;
  }
  
  .sidebar a:hover {
    background-color: #f1f1f1;
    color: #fa8d8d;
  }
  
  .sidebar .active {
    background-color: #f7df1e;
    font-weight: bold;
    color: black;
  }
  
  /* src/styles/landing/Footer.css */

.landing-footer {
  background-color: #1a237e; /* Dark bluish color for the footer background */
  color: #ffffff;
  padding: 20px 0;
  text-align: center;
  border-top: 3px solid #6a1b9a; /* Optional: top border for separation */
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-container p {
  margin: 0;
  font-size: 14px;
  color: #f5f5f5;
}

.footer-links {
  margin-top: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.footer-link {
  color: #ff4081; /* Link color */
  text-decoration: none;
  margin: 0 10px;
  transition: color 0.3s ease;
}

.footer-link:hover {
  color: #00c853; /* Hover color */
}

.link-separator {
  color: #ffffff;
}
