/* Sidebar Container */
.sidebar {
  width: 240px;
  height: 100vh;
  background-color: #c4ccd8; /* Sidebar background color */
  display: flex;
  flex-direction: column;
  padding: 20px;
  color: #1d2b64; /* Primary color for text */
}

/* Navigation Links Container */
.nav-links {
  display: flex;
  flex-direction: column;
}

/* Navigation Link Styling */
.nav-link {
  display: flex;
  align-items: center;
  padding: 12px 20px;
  text-decoration: none;
  color: #1d2b64; /* Default text color */
  font-size: 16px;
  margin-bottom: 8px;
  border-radius: 0; /* Remove box around text */
  transition: background-color 0.3s, color 0.3s;
}

/* Icon Styling */
.nav-link .icon {
  margin-right: 10px;
  color: #1d2b64; /* Default icon color */
}

/* Hover Effect for Navigation Links */
.nav-link:hover {
  background-color: #c4ccd8; /* Keep background color the same or set to another if needed */
  color: #1d2b64 !important; /* Change text color to #1d2b64 on hover */
}

/* Change icon color to #1d2b64 on hover */
.nav-link:hover .icon {
  color: #1d2b64 !important; /* Change icon color to #1d2b64 on hover */
}

/* Active Link Styling */
.nav-link.active {
  background-color: #1d2b64; /* Active link background blue */
  color: white !important; /* Active link text color white */
  font-weight: bold;
}

/* Icon Active State */
.nav-link.active .icon {
  color: white !important; /* Make icon white when active */
}

/* Combine Active and Hover States */
.nav-link.active:hover {
  color: #1d2b64 !important; /* Change text color to #1d2b64 on hover if active */
}

/* Icon Active Hover State */
.nav-link.active:hover .icon {
  color: #1d2b64 !important; /* Change icon color to #1d2b64 on hover if active */
}
