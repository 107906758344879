.document-grid-container {
  padding: 20px;
}

.document-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

/* First 4 items */
.document-card:nth-child(-n+4) {
  grid-column: span 3;
}

/* Last 3 items */
.document-card:nth-child(n+5) {
  grid-column: span 4;
}

.document-card {
  background: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.2s ease;
}

.document-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.document-icon {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.document-name {
  font-size: 1.1rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 15px;
}

.document-actions {
  display: flex;
  gap: 10px;
  width: 100%;
}

.action-button {
  flex: 1;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  transition: background-color 0.2s ease;
}

.view-button {
  background-color: #e3f2fd;
  color: #1976d2;
}

.view-button:hover {
  background-color: #bbdefb;
}

.download-button {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.download-button:hover {
  background-color: #c8e6c9;
}

.button-icon {
  font-size: 1.1rem;
}

@media (max-width: 768px) {
  .document-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .document-card:nth-child(-n+4),
  .document-card:nth-child(n+5) {
    grid-column: span 1;
  }
}

@media (max-width: 480px) {
  .document-grid {
    grid-template-columns: 1fr;
  }
  
  .document-card:nth-child(-n+4),
  .document-card:nth-child(n+5) {
    grid-column: span 1;
  }
}
