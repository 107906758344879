/* src/styles/landing.css */

body, html {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
}
  
/* Header */
.landing-header {
    background: #2a73e8;
    color: white;
    padding: 20px 0;
}
  
.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;
}
  
.logo h1 {
    margin: 0;
    font-size: 24px;
}
  
.navigation {
    display: flex;
    align-items: center;
}
  
.login-button {
    background: white;
    color: #2a73e8;
    padding: 10px 15px;
    border-radius: 5px;
}
  
/* Main Content */
.main-content {
    padding: 40px;
}
  
.intro-section {
    text-align: center;
    margin-bottom: 40px;
}
  
.intro-section h2 {
    font-size: 36px;
}
  
.intro-section p {
    font-size: 18px;
}
  
.cta-button {
    background-color: #2a73e8;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
  
.features-section {
    text-align: center;
    margin-bottom: 40px;
}
  
.features-list {
    display: flex;
    justify-content: center;
    gap: 20px;
}
  
.feature-item {
    background: #f8f8f8;
    padding: 20px;
    width: 200px;
    border-radius: 10px;
}

/* Services Grid */
.services-section {
    margin: 40px 0;
}

.services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.service-item {
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transition: transform 0.3s ease;
}

.service-item:hover {
    transform: translateY(-5px);
}

.service-icon {
    display: block;
    width: 50px;
    height: 50px;
    margin: 0 auto 15px;
    background-color: #2a73e8;
    border-radius: 50%;
}

/* Detailed Services Grid */
.detailed-services {
    margin: 40px 0;
    padding: 20px;
}

.detailed-services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.detailed-service-item {
    display: flex;
    align-items: flex-start;
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.service-number {
    background: #2a73e8;
    color: white;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    flex-shrink: 0;
}

.service-content {
    flex-grow: 1;
}

.service-content h4 {
    margin: 0 0 10px 0;
    color: #333;
}

.service-content p {
    margin: 0;
    color: #666;
    font-size: 14px;
}
  
/* Footer */
.landing-footer {
    background: #2a73e8;
    color: white;
    padding: 20px 0;
    text-align: center;
}

.footer-links {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}
  
.footer-link {
    text-decoration: none;
    color: white;
    padding: 0 10px;
}
  
.link-separator {
    color: white;
    margin: 0 10px;
}

.landing-page {
    background: #c4ccd8;
}
