/* Hero Section Styling */
.hero-section {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  padding: 2rem;
}

.hero-content {
  text-align: center;
  max-width: 800px;
}

.hero-title {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #333;
  line-height: 1.2;
}

.animated-words {
  display: flex; /* Change to flex to center the words */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 1.2em; /* Ensures enough space for the text */
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 2rem; /* Add margin to create space below */
}

.animated-words span {
  display: block;
  position: absolute;
  opacity: 0;
  font-size: 3rem; /* Match the font size with the title */
  font-weight: 700; /* Match the font weight with the title */
  color: #1d2b64; /* Change this color to your desired one */
  animation: animateWords 9s linear infinite;
}

.animated-words span:nth-child(1) {
  animation-delay: 0s;
}

.animated-words span:nth-child(2) {
  animation-delay: 3s;
}

.animated-words span:nth-child(3) {
  animation-delay: 6s;
}

@keyframes animateWords {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  10% {
    opacity: 1;
    transform: translateY(0);
  }
  30% {
    opacity: 1;
    transform: translateY(0);
  }
  40% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}

.hero-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.hero-button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.primary-button {
  background-color: #1d2b64;
  color: white;
}

.primary-button:hover {
  background-color: #1d2b64;
  transform: scale(1.05);
}
