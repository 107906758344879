.main-content {
  padding: 15px;
  background-color: #f8f9fa;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 15px;
  background-color: #ebedf3;
  color: #210a48;
  border-radius: 8px;
  margin-bottom: 15px;
}

.header-buttons {
  display: flex;
  gap: 10px;
}

.register-button {
  padding: 8px 15px;
  background-color: #1d2b64;
  color: white;
  border-radius: 6px;
  cursor: pointer;
  border: none;
  transition: all 0.3s ease;
}

.no-underline {
  text-decoration: none;
}

.register-button:hover {
  background-color: #2a3875;
  transform: translateY(-2px);
}

.registration-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(29, 43, 100, 0.1);
}

.registration-table th, 
.registration-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #e0e4e9;
}

.registration-table th {
  background-color: #1d2b64;
  color: #ffffff;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
}

.registration-table tr:hover {
  background-color: #f8f9fa;
}

.status {
  display: inline-block;
  padding: 4px 10px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  min-width: 100px;
}

.status.registered {
  background-color: #28a745;
  color: #ffffff;
}

.status.pending {
  background-color: #ffc107;
  color: #1d2b64;
}

.status.failed,
.status.cancelled {
  background-color: #dc3545;
  color: #ffffff;
}

.status.processing {
  background-color: #1d2b64;
  color: #ffffff;
}

.view-details-button,
.cancel-button,
.resend-button {
  padding: 6px 12px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
}

.view-details-button {
  background-color: #1d2b64;
  color: #ffffff;
}

.view-details-button:hover {
  background-color: #2a3875;
  transform: translateY(-2px);
}

.cancel-button {
  background-color: #dc3545;
  color: #ffffff;
}

.cancel-button:hover {
  background-color: #c82333;
  transform: translateY(-2px);
}

.cancel-button:disabled {
  background-color: #c4ccd8;
  cursor: not-allowed;
  transform: none;
}

.resend-button {
  background-color: #ffc107;
  color: #1d2b64;
}

.resend-button:hover {
  background-color: #e0a800;
  transform: translateY(-2px);
}

.pagination-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
  padding: 12px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(29, 43, 100, 0.05);
}

.pagination-controls button {
  padding: 6px 12px;
  background-color: #1d2b64;
  color: #ffffff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.pagination-controls button:hover:not(:disabled) {
  background-color: #2a3875;
  transform: translateY(-2px);
}

.pagination-controls button:disabled {
  background-color: #c4ccd8;
  cursor: not-allowed;
}

.pagination-controls span {
  font-size: 14px;
  font-weight: 500;
  color: #1d2b64;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(29, 43, 100, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  box-shadow: 0 4px 20px rgba(29, 43, 100, 0.15);
}

.modal h3 {
  color: #1d2b64;
  margin-bottom: 12px;
  font-size: 20px;
}

.modal p {
  font-size: 16px;
  color: #4a5568;
  margin-bottom: 20px;
  line-height: 1.5;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.confirm-button {
  background-color: #dc3545;
  color: #ffffff;
  padding: 8px 15px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.confirm-button:hover {
  background-color: #c82333;
  transform: translateY(-2px);
}

.cancel-modal-button {
  background-color: #1d2b64;
  color: #ffffff;
  padding: 8px 15px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.cancel-modal-button:hover {
  background-color: #2a3875;
  transform: translateY(-2px);
}

.loan-details-container {
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(29, 43, 100, 0.1);
  position: relative;
}

.loan-info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0px;
  margin-top: 15px;
}

.loan-info p {
  margin: 2px 0;
  padding: 8px;
  background-color: #f8f9fa;
  border-radius: 6px;
  color: #1d2b64;
}

.loan-info p strong {
  color: #1d2b64;
  margin-right: 6px;
}

.close-button {
  position: absolute;
  top: 12px;
  right: 12px;
  background: none;
  border: none;
  font-size: 20px;
  color: #1d2b64;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.close-button:hover {
  background-color: #f8f9fa;
  transform: translateY(-2px);
}
