/* General NavBar Styling with gradient, curves, and shadow */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff; /* Change background to white */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Box shadow for depth */
  font-family: 'Poppins', sans-serif; /* Clean, modern font */
  color: #1d2b64; /* Change text color to dark blue */
  position: sticky;
  top: 0;
  z-index: 1000;
  padding: 10px 30px; /* Extra padding for elegance */
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  transition: all 0.3s ease-in-out; /* Smooth transitions */
}

/* Logo Styling */
.navbar-logo img {
  height: 60px;
  cursor: pointer;
}

/* Nav Links Styling */
.navbar-links-left {
  list-style-type: none;
  display: flex;
  align-items: center;
  gap: 40px; /* Adjust spacing between menu items */
  padding-left: initial;
}

.navbar-links-right {
  list-style-type: none;
  display: flex;
  align-items: center;
  gap: 40px; /* Adjust spacing between menu items */
}

.navbar-links-left li, .navbar-links-right li {
  position: relative;
  font-size: 1.2rem;
  font-weight: 300;  /* Bold weight for emphasis */
  cursor: pointer;
  transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
  padding: 8px 12px;
  border-radius: 20px;
  color: #1d2b64; /* Change text color to dark blue */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
}

.navbar-links-left li:hover, .navbar-links-right li:hover {
  text-decoration: underline;
  transform: scale(1.1); /* Slight zoom on hover */
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3); /* Extra shadow on hover */
}

/* Right side of the navbar */
.navbar-right {
  display: flex;
  align-items: center;
  gap: 20px; /* Adjust spacing between menu items and button */
}

/* Popup Styling */
.products-popup {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #ffffff;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  border-radius: 20px; /* Rounded edges */
  animation: fadeIn 0.3s ease-in-out;
  z-index: 100;
  color: #333;
}

/* Popup Item Styling */
.popup-item {
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 12px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  color: #333; /* Default text color for product items */
}
.popup-item:hover {
  background-color: #c4ccd8; /* Dark bluish background on hover */
  color: #1d2b64; /*Change text color to white for contrast*/
  transform: translateY(-5px); /* Elevate slightly on hover */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Uniform shadow on hover */
}

/* Icon Styling */
.popup-icon {
  font-size: 1.5rem;
  color: #1d2b64; /* Dark bluish tone for icons by default */
  margin-right: 10px;
}

.popup-item:hover .popup-icon {
  color: white; /* Icons will also turn white on hover */
}
/* Book a Demo Button Styling */
.navbar-book-demo {
  margin-left: auto;
}

.book-demo-button {
  background: linear-gradient(45deg, #1d2b64, #283c86); /* Dark bluish gradient */
  color: white; /* White text */
  border: none;
  padding: 10px 25px;
  font-size: 1.1rem;
  font-weight: 300;
  border-radius: 30px; /* Curved button */
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.book-demo-button:hover {
  transform: scale(1.1); /* Slight zoom effect on hover */
  background: linear-gradient(45deg, #0f0f0f, #1a1a1a); /* Darker hover gradient */
}

/* Font Styling */
body, li, h4, p {
  font-family: 'Poppins', sans-serif; /* Clean, modern font */
}

h4 {
  font-size: 1.1rem;
  font-weight: 700; /* Bolder for emphasis */
}

p {
  font-size: 0.95rem;
  color: #666; /* Soft gray for text inside popup */
}

/* Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}