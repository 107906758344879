.card {
  width: 30%;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Added shadow */
  transition: box-shadow 0.3s ease; /* Smooth shadow transition on hover */
}

.card:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
}

.chart-bar {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  height: 150px; /* Added height to the chart container */
  align-items: flex-end; /* Align bars to the bottom */
}

.bar {
  width: 15px; /* Slightly increased bar width */
  background: linear-gradient(to top, #1d2b64, #4b7bec); /* Gradient color */
  border-radius: 5px; /* Rounded corners */
  transition: transform 0.3s ease-in-out; /* Added animation */
  position: relative; /* For positioning the value */
}

.bar-value {
  position: absolute;
  bottom: 100%; /* Position above the bar */
  left: 50%;
  transform: translateX(-50%);
  color: #1d2b64; /* Text color for the value */
  font-weight: bold;
  font-size: 12px; /* Smaller font size */
  margin-bottom: 5px; /* Space between bar and value */
}

/* Hover effect on bars */
.bar:hover {
  transform: scaleY(1.1); /* Scale up the bar slightly */
}
