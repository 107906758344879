/* Dashboard Layout Container */
.dashboard-layout {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  /* Main Container */
  .main-container {
    display: flex;
    flex: 1;
    height: calc(100vh - 60px); /* Subtracting top-nav height */
  }
  
  /* Sidebar */
  .sidebar {
    flex-shrink: 0;
  }
  
  /* Main Content Area */
  .main-content {
    flex-grow: 1;
    padding: 20px;
    overflow-y: auto;
    background-color: #f4f5f7;
  }
  