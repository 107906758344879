.top-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff; /* Adjust the background color as needed */
  padding: 10px 20px;
  height: 70px; /* Increase the height to make the navbar thicker */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo-section {
  display: flex;
  align-items: center;
}

.logo {
  height: 50px; /* Increase the height to make the logo bigger */
  width: auto; /* Maintain aspect ratio */
}

.user-controls {
  display: flex;
  align-items: center;
  gap: 20px;
}

.icon {
  color: #1d2b64; /* Uniform blue color for icons */
  font-size: 1.5rem;
  cursor: pointer;
}

.logout-button {
  background-color: #1d2b64; /* Same background color as login button */
  color: white;
  border: none;
  padding: 10px 20px; /* Same padding as login button */
  border-radius: 25px; /* Same border radius as login button */
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease; /* Same transition as login button */
}

.logout-button:hover {
  background-color: #16224f; /* Same hover background color as login button */
  transform: scale(1.05); /* Same hover transform as login button */
}

.top-nav a, .top-nav button {
  color: #ffffff; /* Uniform blue color for text */
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
}